import { Injectable, inject } from "@angular/core";
import { Observable, forkJoin, map, of } from "rxjs";

import { ServerGatewayBase } from "@logex/framework/lg-application";

import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";

import { FiltersService, RegistryService, SettingsService } from "@codman/patients/util-services";

import {
    MultipleTypePatientScoresResponse,
    PatientScoresResponse,
    TreatmentComplicationsResponse,
    TreatmentCourseResponse,
    TreatmentResponse,
} from "./data-access-api.types";

@Injectable({
    providedIn: "root",
})
export class DataAccessApiService extends ServerGatewayBase {
    private _configurationService = inject(SharedConfigService);
    private _registryService = inject(RegistryService);
    private _settingsService = inject(SettingsService);
    private _filtersService = inject(FiltersService);

    constructor() {
        super();
        this._setBaseUrl(this._configurationService.getConfiguration()?.apiUrl ?? "");
    }

    getTreatments(): Observable<TreatmentResponse[]> {
        const subsetApiName = this._getCurrentSubsetApiName();
        if (subsetApiName) {
            return this._getFiltered<TreatmentResponse[]>(`${subsetApiName}/treatments`) ?? [];
        } else {
            return of([]);
        }
    }

    getTreatmentComplications(): Observable<TreatmentComplicationsResponse[]> {
        const subsetApiName = this._getCurrentSubsetApiName();
        const treatmentApiName = this._getCurrentTreatmentApiName();
        if (subsetApiName && treatmentApiName) {
            return (
                this._getFiltered<TreatmentComplicationsResponse[]>(
                    `${subsetApiName}/treatments/${treatmentApiName}/complications`,
                ) ?? []
            );
        } else {
            return of(<TreatmentComplicationsResponse[]>[]);
        }
    }

    getTreatmentCourse(): Observable<TreatmentCourseResponse[]> {
        const subsetApiName = this._getCurrentSubsetApiName();
        const treatmentApiName = this._getCurrentTreatmentApiName();
        if (subsetApiName && treatmentApiName) {
            return (
                this._getFiltered<TreatmentCourseResponse[]>(
                    `${subsetApiName}/treatments/${treatmentApiName}/course`,
                ) ?? []
            );
        } else {
            return of(<TreatmentCourseResponse[]>[]);
        }
    }

    getPatientScores(type: string): Observable<PatientScoresResponse[]> {
        const subsetApiName = this._getCurrentSubsetApiName();
        const treatmentApiName = this._getCurrentTreatmentApiName();
        if (subsetApiName && treatmentApiName) {
            return this._getFiltered<PatientScoresResponse[]>(
                `${subsetApiName}/treatments/${treatmentApiName}/patientscores/${type}`,
            );
        } else {
            return of(<PatientScoresResponse[]>[]);
        }
        // return of(<PatientScoresResponse[]>[
        //     {
        //         type: "Median",
        //         label: "AfterDiagnosis",
        //         allPatients: {
        //             value: 74,
        //             count: 60,
        //             anonymized: false,
        //         },
        //         patientsLikeMe: {
        //             value: 71,
        //             count: 60,
        //             anonymized: false,
        //         },
        //     },
        //     {
        //         type: "Median",
        //         label: "SixMonthsAfterSurgery",
        //         allPatients: {
        //             value: 48,
        //             count: 60,
        //             anonymized: false,
        //         },
        //         patientsLikeMe: {
        //             value: 49,
        //             count: 60,
        //             anonymized: false,
        //         },
        //     },
        //     {
        //         type: "Median",
        //         label: "TwelveMonthsAfterSurgery",
        //         allPatients: {
        //             value: undefined,
        //             count: 60,
        //             anonymized: false,
        //         },
        //         patientsLikeMe: {
        //             value: undefined,
        //             count: 60,
        //             anonymized: false,
        //         },
        //     },
        // ]);
    }

    getAllPatientScores(types: string[]): Observable<MultipleTypePatientScoresResponse> {
        const subsetApiName = this._getCurrentSubsetApiName();
        const treatmentApiName = this._getCurrentTreatmentApiName();
        if (subsetApiName && treatmentApiName) {
            return forkJoin(
                types.map(type => {
                    return this._getFiltered<PatientScoresResponse[]>(
                        `${subsetApiName}/treatments/${treatmentApiName}/patientscores/${type}`,
                    ).pipe(
                        map(response => ({
                            type,
                            data: response,
                        })),
                    );
                }),
            );
        } else {
            return of([]);
        }
    }

    private _getCurrentSubsetApiName(): string {
        const subset = this._settingsService.subset;
        const subsetConfig = this._registryService.getSubsetConfig(subset);
        return subsetConfig ? subsetConfig.apiName : "";
    }

    private _getCurrentTreatmentApiName(): string {
        const treatment = this._settingsService.treatment;
        return treatment ? treatment.apiValue : "";
    }

    private _getFiltered<T>(url: string): Observable<T> {
        const filtersQueryString = this._filtersService.getFiltersQueryString();
        return this.get<T>(url + "?" + filtersQueryString);
    }
}
