import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
    LG_APP_INFO,
    LG_MATOMO_CONFIGURATION,
    LG_NAVIGATION,
    LgMatTrackingService,
} from "@logex/framework/lg-application";

import {
    CodmanBackendInterceptor,
    SharedConfigService,
    SharedUtilLogexFrameworkRootModule,
} from "@codman/shared/util-logex-framework-setup";
import { SharedUtilAuthorizationModule } from "@codman/shared/util-authorization";
import { MatomoConfiguration } from "@codman/shared/util-tracking";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LgFilterSetService, LgFiltersetModule } from "@logex/framework/lg-filterset";
import { FilterFactory } from "@codman/shared/util-filters";
import { navigation } from "@codman/patients/data-access-base";
import { LgFiltersetStateGateway, LgLayoutModule } from "@logex/framework/lg-layout";
import { LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        LgFiltersetModule.forRoot(),
        LgLayoutModule,
        SharedUtilLogexFrameworkRootModule,
        SharedUtilAuthorizationModule.forRoot(),
    ],
    providers: [
        LgFiltersetStateGateway,
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: SharedConfigService) => startup.initialize(),
            deps: [SharedConfigService],
            multi: true,
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configService: SharedConfigService) => {
                const config = configService.getConfiguration();
                const buildNumber = config?.version;
                return {
                    environment: config?.environment,
                    fullAppName: "Patients Like Me",
                    productId: "patients",
                    toolInstanceName: "",
                    versionNumber: `2.${buildNumber ?? "000"}`,
                    overrideCurrencyPrefix: "",
                    overrideCurrencySuffix: "",

                    doNotDoGaTracking: () => false,
                    isProduction: () => config?.environment === "prod",
                };
            },
            deps: [SharedConfigService],
        },
        {
            provide: LG_NAVIGATION,
            useFactory: () => navigation(),
        },
        FilterFactory,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CodmanBackendInterceptor,
            multi: true,
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
        LgFilterSetService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
