import { Injectable } from "@angular/core";
import { defaultRegistry, registryConfigs } from "@codman/patients/data-access-base";
import { RegistryConfig, SubsetConfig, ITreatment } from "./registry.types";

@Injectable({
    providedIn: "root",
})
export class RegistryService {
    getRegistryConfig(): RegistryConfig {
        return registryConfigs[defaultRegistry];
    }

    getSubsetConfig(subsetName: string): SubsetConfig | undefined {
        const registryConfig = this.getRegistryConfig();
        return registryConfig.subsets.find(subset => subset.value === subsetName);
    }

    getTreatment(subsetName: string, treatmentName: string): ITreatment | undefined {
        const config = this.getSubsetConfig(subsetName);
        return (
            config &&
            config.treatments.find(
                treatment =>
                    treatment.id === treatmentName || treatment.routePath === treatmentName,
            )
        );
    }
}
