export interface AnonymizedFields {
    anonymized: boolean;
}

export interface TreatmentResponse extends AnonymizedFields {
    id: string;
    patientsCount: number;
}

export interface TreatmentComplicationsResponse extends IComplication {
    complicationTypes: IComplication[];
}

interface IComplication extends AnonymizedFields {
    id: string;
    patientsLikeMe: number;
    patientsLikeMeDenominator: number;
    type: string;
}

interface TreatmentCourseBase extends AnonymizedFields {
    id: string;
    patientsLikeMe: number;
    patientsLikeMeDenominator: number;
}

export interface TreatmentCoursePercentage extends TreatmentCourseBase {
    type: "Percentage";
}
export interface TreatmentCourseMedian extends TreatmentCourseBase {
    patientsLikeMeFirstQuartile: number;
    patientsLikeMeThirdQuartile: number;
    type: "Median";
}

export type TreatmentCourseResponse = TreatmentCoursePercentage | TreatmentCourseMedian;

export interface PatientScoresResponse {
    type: string;
    label: string;
    allPatients: PatientScore;
    patientsLikeMe: PatientScore;
}

export type MultipleTypePatientScoresResponse = Array<{
    type: string;
    data: PatientScoresResponse[];
}>;

export interface PatientScore extends AnonymizedFields {
    value: number;
    count: number;
}
