import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { RegistryService } from "./registry.service";
import { ITreatment } from "./registry.types";

@Injectable({
    providedIn: "root",
})
export class SettingsService {
    private _subset$ = new BehaviorSubject<string>("");
    private _treatment$ = new BehaviorSubject<ITreatment | undefined>(undefined);

    constructor(private _registryService: RegistryService) {}

    get subset$(): Observable<string> {
        return this._subset$.pipe(filter(subset => subset != null));
    }

    get subset(): string {
        return this._subset$.value;
    }

    set subset(value: string) {
        const config = this._registryService.getSubsetConfig(value);
        if (config == null) return;
        this._subset$.next(value);
    }

    get treatment$(): Observable<ITreatment | undefined> {
        return this._treatment$.pipe(filter(treatment => treatment != null));
    }

    get treatment(): ITreatment | undefined {
        return this._treatment$.value;
    }

    setTreatment(value: string): void {
        const treatment = this._registryService.getTreatment(this._subset$.value, value);
        this._treatment$.next(treatment);
    }
}
