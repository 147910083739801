import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";
import { AccessDeniedComponent } from "@codman/shared/feature-static-pages";
import { patientsGuard } from "./patients-guard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
    },
    {
        path: "dashboard/:subset",
        loadChildren: () =>
            import("@codman/patients/dashboard").then(m => m.PatientsDashboardModule),
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate, patientsGuard],
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "**",
        redirectTo: "dashboard/rectal-cancer",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { initialNavigation: "enabledBlocking" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
